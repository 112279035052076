import { useDispatch, useSelector } from "react-redux";
import { getUtgBundle } from "store/actions/CreativeAI_Revamp/UniTextGen/uniTextGen";
import { CreateVariantsState } from "store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { InitialMediaStudioType } from "store/types/CreativeAI/Facebook/CreateVariant/mediaStudio.types";
import { FBHomeScreenInitialStateType } from "store/types/CreativeAI_Revamp/HomeScreen/homeScreen.types";
import { FacebookInitialState } from "store/types/CreativeAI_Revamp/facebook.types";
import { getUTGBundlePayload, getFormValues } from "../../utils/form.utils";
import {
  checkAdType,
  checkAdTypeForPublish,
  fetchAdTypeField,
  mapAdAdSetToElement,
} from "../../utils/common.utils_old";
import {
  SET_AD_ADSET_FIELD_LIST,
  SET_ELEMENT_CREATIVE_AI,
} from "store/types/CreativeAI/Facebook/Main/Main";
import { initialState } from "store/types/auth";
import { useMemo } from "react";
import { APIGenerationType } from "../../facebook.types";
import { filterVariantData } from "../../utils/filterLevers.utils";
import { setUTGData } from "store/actions/CreativeAI_Revamp/facebook.actions";
import { getDisplayUrlAndUrl } from "components/CreativeAI/Facebook/utils/common.utils";
import { fetchPerformanceScore } from "store/actions/CreativeAI_Revamp/HomeScreen/Editor/editor.actions";
import { AdAccountState } from "store/types/adaccount";

export const useUTG = () => {
  const dispatch = useDispatch();
  const { utgData } = useSelector(
    (state: { facebookReducer: FacebookInitialState }) => state.facebookReducer
  );
  const { LEVERS } = useSelector((state: any) => ({
    LEVERS: state.MainReducer.defaultLevers,
  }));
  const { variantList, selectedAd, createSpec } = useSelector(
    (state: { createVariantReducer: CreateVariantsState | any }) =>
      state.createVariantReducer
  );
  const { BUSINESS_UNIT_BY_ID_DATA, CREATIVE_ELEMENT_AI } = useSelector(
    (state: { selectCohortReducer: any; MainReducer: any }) => ({
      BUSINESS_UNIT_BY_ID_DATA: state.MainReducer.variantWithId,
      CREATIVE_ELEMENT_AI: state.MainReducer.elementCreativeAi,
    })
  );
  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const { COHORT_DATA } = useSelector(
    (state: { selectCohortReducer: any; createVariantReducer: any }) => ({
      COHORT_DATA: state?.selectCohortReducer?.cohortData,
    })
  );
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand } = adaccount;
  const { adAccountsSelection, adSetSelection } = useSelector(
    (state: { fbHomeScreenReducer: FBHomeScreenInitialStateType }) =>
      state.fbHomeScreenReducer
  );
  const { getMediaStudioData } = useSelector(
    (state: { mediaStudioReducer: InitialMediaStudioType }) =>
      state.mediaStudioReducer
  );
  const data: APIGenerationType[] = useMemo(
    () =>
      filterVariantData(LEVERS, [
        "user",
        "ad",
        "adset",
        "campaign",
        "ad_video",
        "ad_image",
      ]),
    [LEVERS]
  );

  const generateUTGRecommendations = (text: string) => {
    dispatch(
      setUTGData({
        path: selectedAd?.id,
        value: {
          isMediaStudioAvailable: selectedAd?.isMediaStudioAvailable,
          variantsCount: 0,
          inDraft: variantList?.length,
          status: {
            inProgress: true,
            step: 0,
            progress: 0,
            isError: false,
          },
          input_text: text,
          adsetId: adSetSelection?.selectedAdSetId,
          adAccountId: adAccountsSelection?.selectedAdAccountId,
          elementId: adAccountsSelection?.selectedElementId,
          headline: fetchAdTypeField(createSpec, "headline"),
          description: fetchAdTypeField(createSpec, "description"),
          primaryText: fetchAdTypeField(createSpec, "primary_text"),
          adType: checkAdType(createSpec),
          settings: {
            displayUrl: getDisplayUrlAndUrl(createSpec).displayUrl || "",
            url: getDisplayUrlAndUrl(createSpec).url || "",
            fbPage: createSpec?.object_story_spec?.page_id || "",
            instagramPage:
              createSpec?.object_story_spec?.instagram_actor_id || "",
            pixel: selectedAd?.tracking_specs?.[0]?.fb_pixel?.[0] || "",
            adType: checkAdTypeForPublish(createSpec),
            adName: selectedAd?.name,
          },
        },
      })
    );
    if (!utgData?.[selectedAd?.id]?.defaultScore) {
      dispatch(
        fetchPerformanceScore({
          body: {
            account_id: adAccountsSelection?.selectedAdAccountId as string,
            ad_type:
              checkAdType(createSpec) === "link_data"
                ? "SINGLE_IMAGE_AD"
                : "VIDEO_AD",
            ad_id: selectedAd?.id,
            assets: [
              {
                asset_type: "primary_text",
                asset_text: fetchAdTypeField(createSpec, "primary_text"),
              },
              {
                asset_type: "headline",
                asset_text: fetchAdTypeField(createSpec, "headline"),
              },
              {
                asset_type: "description",
                asset_text: fetchAdTypeField(createSpec, "description"),
              },
            ],
            bu_name: BUSINESS_UNIT_BY_ID_DATA?.businessUnit,
            bu_detail: BUSINESS_UNIT_BY_ID_DATA?.businessUnitDetail,
            is_bundle: true,
          },
          callback: ({ error, performanceScore }) => {
            if (!error) {
              dispatch(
                setUTGData({
                  path: [selectedAd?.id, "defaultScore"],
                  value: performanceScore,
                })
              );
            } else {
              dispatch(
                setUTGData({
                  path: [selectedAd?.id, "defaultScore"],
                  value: null,
                })
              );
            }
          },
        })
      );
    }
    const createSpecAPI = selectedAd?.adcreatives?.data[0];
    const adType = checkAdType(createSpecAPI);
    let newLeverData: any = {};
    if (selectedAd?.isMediaStudioAvailable && getMediaStudioData?.isData) {
      if (adType === "link_data") {
        newLeverData = data.filter((item) => item.generation_type !== "video");
      } else {
        newLeverData = data.filter((item) => item.generation_type !== "image");
      }
    } else {
      newLeverData = data.filter(
        (item) =>
          item.generation_type !== "video" && item.generation_type !== "image"
      );
    }
    const { newCreativeElementAI, adAdSetFieldList } = mapAdAdSetToElement({
      createSpec: createSpecAPI,
      targeting: COHORT_DATA?.facebookData?.targeting,
      creativeElementAI: CREATIVE_ELEMENT_AI.data,
      levers: newLeverData,
      getMediaStudioData,
    });

    dispatch({
      type: SET_ELEMENT_CREATIVE_AI,
      payload: {
        data: newCreativeElementAI,
      },
    });
    dispatch({
      type: SET_AD_ADSET_FIELD_LIST,
      payload: adAdSetFieldList,
    });
    const payload = {
      data: getUTGBundlePayload(
        {
          ...getFormValues(newCreativeElementAI, true),
          businessUnit: BUSINESS_UNIT_BY_ID_DATA?.businessUnit,
          businessUnitDetail: BUSINESS_UNIT_BY_ID_DATA?.businessUnitDetail,
        },
        fetchAdTypeField(createSpec, "primary_text"),
        fetchAdTypeField(createSpec, "headline"),
        fetchAdTypeField(createSpec, "description"),
        text,
        selectedAd?.id,
        checkAdTypeForPublish(createSpec),
        adAccountsSelection?.selectedAdAccountId || ""
      ),
      user: auth.user,
    };
    dispatch(
      getUtgBundle(payload, (data: any, error: any) => {
        if (!error) {
          dispatch(
            setUTGData({
              path: selectedAd?.id,
              value: { requestId: data?.requestid },
            })
          );
        } else {
          dispatch(
            setUTGData({
              path: [selectedAd?.id, "status"],
              value: { isError: true, inProgress: false },
            })
          );
        }
      })
    );
  };
  return { generateUTGRecommendations, utgData };
};
