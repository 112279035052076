import { getDisplayUrlAndUrl } from "components/CreativeAI/Facebook/utils/common.utils";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addVariant } from "store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import { generateBillboardLink } from "store/actions/CreativeAI/Facebook/CreateVariant/creativeAnimater";
import { AdAccountState } from "store/types/adaccount";
import {
  CreateVariantsState,
  SET_ADD_VARIANT_RESPONSE,
} from "store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { CANCEL_GET_BILLBOARD_API } from "store/types/CreativeAI/Facebook/CreateVariant/creativeAnimater";
import { FBHomeScreenInitialStateType } from "store/types/CreativeAI_Revamp/HomeScreen/homeScreen.types";
import {
  checkAdType,
  checkAdTypeForPublish,
  fetchAdTypeField,
} from "../../utils/common.utils_old";
import { fetchPerformanceScore } from "store/actions/CreativeAI_Revamp/HomeScreen/Editor/editor.actions";
import { PerformanceScoreType } from "store/types/CreativeAI_Revamp/HomeScreen/Editor/editor.types";

export const useCreativeAnimator = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const dispatch = useDispatch();
  //   const {
  //     selectedAiGroup,
  //     selectedAdAccount: { adAccountId },
  //   } = useSelector((state: any) => state.aiGroupReducer);
  const { adAccountsSelection, adSetSelection } = useSelector(
    (state: { fbHomeScreenReducer: FBHomeScreenInitialStateType }) =>
      state.fbHomeScreenReducer
  );
  const { COHORT_DATA } = useSelector(
    (state: { selectCohortReducer: any; createVariantReducer: any }) => ({
      COHORT_DATA: state?.selectCohortReducer?.cohortData,
    })
  );
  const { BUSINESS_UNIT_BY_ID_DATA } = useSelector(
    (state: { selectCohortReducer: any; MainReducer: any }) => ({
      BUSINESS_UNIT_BY_ID_DATA: state.MainReducer.variantWithId,
    })
  );
  const { selectedAd, variantList, createSpec } = useSelector(
    (state: { createVariantReducer: CreateVariantsState | any }) =>
      state.createVariantReducer
  );
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand } = adaccount;

  const onCloseCreateAnimater = () => {
    dispatch({
      type: CANCEL_GET_BILLBOARD_API,
    });
  };

  const resetFlag = () => {
    setIsFailed(false);
  };

  const adVariants = (
    data?: { id: string; redirectUrl: string },
    performanceScore?: PerformanceScoreType
  ) => {
    dispatch(
      addVariant({
        user: "",
        params: {
          headline: { name: fetchAdTypeField(createSpec, "headline") },
          description: {
            name: fetchAdTypeField(createSpec, "description"),
          },
          primaryText: {
            name: fetchAdTypeField(createSpec, "primary_text"),
          },
          adId: selectedAd?.id,
          elementId: adAccountsSelection?.selectedElementId,
          adAccountId: adAccountsSelection?.selectedAdAccountId,
          adsetId: adSetSelection?.selectedAdSetId,
          variantType: "animator",
          billboardMediaId: data?.id,
          payload: {
            settings: {
              variantName: selectedAd.name + "_CAI_cv" + variantList.length,
              displayUrl: getDisplayUrlAndUrl(createSpec).displayUrl ?? "",
              url: getDisplayUrlAndUrl(createSpec).url ?? "",
              fbPage: createSpec?.object_story_spec?.page_id ?? "",
              instagramPage:
                createSpec?.object_story_spec?.instagram_actor_id ?? "",
              adType: checkAdTypeForPublish(createSpec), // further discussion will going to happen on this
              adName: selectedAd?.name,
            },
          },
          performanceScore: performanceScore ?? "",
        },
        finalAction: SET_ADD_VARIANT_RESPONSE,
        adAccountId: adAccountsSelection?.selectedAdAccountId,
      })
    );
  };

  const onClickCreateAnimater = () => {
    setIsLoading(true);
    dispatch(
      generateBillboardLink({
        body: {
          adAccountId: adAccountsSelection?.selectedAdAccountId,
          adId: selectedAd?.id,
          adSetId: adSetSelection?.selectedAdSetId,
          brandId: selectedBrand?.id,
          creativeImage: createSpec?.image_url,
          headline: fetchAdTypeField(createSpec, "headline"),
          description: fetchAdTypeField(createSpec, "description"),
          ctaText: createSpec?.call_to_action_type,
        },
        callback: ({ isFailed, isLoading, data, cancel }) => {
          console.log(isFailed, isLoading, data, cancel);
          // if we cancel api call
          if (cancel) {
            setIsLoading(false);
            return;
          }
          setIsLoading(isLoading);
          setIsFailed(isFailed);
          /* 
              if we have success response then we will add a new variant
             */

          if (!isFailed) {
            dispatch(
              fetchPerformanceScore({
                body: {
                  account_id:
                    adAccountsSelection?.selectedAdAccountId as string,
                  ad_type:
                    checkAdType(createSpec) === "link_data"
                      ? "SINGLE_IMAGE_AD"
                      : "VIDEO_AD",
                  ad_id: selectedAd?.id,
                  assets: [
                    {
                      asset_type: "primary_text",
                      asset_text: fetchAdTypeField(createSpec, "primary_text"),
                    },
                    {
                      asset_type: "headline",
                      asset_text: fetchAdTypeField(createSpec, "headline"),
                    },
                    {
                      asset_type: "description",
                      asset_text: fetchAdTypeField(createSpec, "description"),
                    },
                  ],
                  bu_name: BUSINESS_UNIT_BY_ID_DATA?.businessUnit,
                  bu_detail: BUSINESS_UNIT_BY_ID_DATA?.businessUnitDetail,
                  is_bundle: true,
                },
                callback: ({ error, performanceScore }) => {
                  adVariants(data, performanceScore as PerformanceScoreType);

                  // we need to think about the loading scenario here
                  // setIsFetchingPFScore(false);
                },
              })
            );
            window.open(data?.redirectUrl, "_blank");
          }
        },
      })
    );
  };

  return {
    onClickCreateAnimater,
    isLoading,
    isFailed,
    setIsLoading,
    onCloseCreateAnimater,
    adVariants,
    resetFlag,
  };
};
