import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Lottie from "lottie-react";
import OnBoardingJson from "../../assets/json/OnBoarding.json";

import { setIsRefreshTokenExpired, setSession } from "../../store/actions/auth";
import { user_session_key } from "../../utils/constants";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  getSessionData,
  setLocalStorageData,
} from "../../utils/commonFunctions";
import { initialState } from "store/types/auth";
import { mixPanelAdEvent } from "../../utils/mixpanel";
import { AUTH0_CLIENT_ID, AUTH0_COOKIE_DOMAIN } from "utils/path";
import Cookies from "universal-cookie";
import { useSearchParams } from "react-router-dom-v5-compat";

const AuthWrapper: React.FC<any> = ({ children }) => {
  const { logout } = useAuth0();
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams<any>();
  const [searchParams] = useSearchParams();
  const isViewedFromBeta = decodeURIComponent(
    searchParams.get("accessToken") || ""
  );
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();
  const history = useHistory();
  const authState = useSelector((state: { auth: initialState }) => state.auth);

  useEffect(() => {
    (async () => {
      try {
        if (isViewedFromBeta) {
          Object.entries(JSON.parse(isViewedFromBeta)).forEach(
            ([key, value]) => {
              setLocalStorageData(key, value);
            }
          );
        } else {
          const data = await getAccessTokenSilently();
          dispatch({ type: "GET_USER_INFO", payload: { user, data } });
        }
        if (isAuthenticated || isViewedFromBeta) {
          const sessionData: any = getSessionData();
          let session: any = {
            refreshToken: sessionData.body.refresh_token,
            accessToken: sessionData.body.access_token,
            expiresAt: sessionData.expiresAt * 1000,
          };

          setLocalStorageData(user_session_key, session);
          dispatch(setSession(session));
          postMessage({ from: "PYXIS_DASHBOARD", data: { session, user } });
        }
      } catch (error) {
        console.log(error);
        if (window.location.pathname !== "/auth/logout")
          window.location.href = "/auth/logout";
        else window.location.href = "/auth/login";
      }
    })();

    const handleMessgae = async (event: any) => {
      if (event.data.from && event.data.from === "PYXIS_EXTENSION") {
        logout();
        localStorage.removeItem(user_session_key);
        dispatch(setIsRefreshTokenExpired(true));
      }
    };
    window.addEventListener("message", handleMessgae, false);

    return () => window.removeEventListener("message", handleMessgae);
  }, [isAuthenticated]);

  const sessionLogout = () => {
    localStorage.clear();
    logout({
      logoutParams: {
        returnTo: `${window.location.origin}/auth/login`,
        client_id: AUTH0_CLIENT_ID,
        federated: true,
        redirect_uri: `${window.location.origin}/auth/login`,
      },
    });
  };

  useEffect(() => {
    const cookies = new Cookies();
    let checkLogout = cookies.get(`auth0.${AUTH0_CLIENT_ID}.is.authenticated`);
    if (
      Boolean(checkLogout) === false &&
      window.location.origin.toString().includes(AUTH0_COOKIE_DOMAIN)
    ) {
      sessionLogout();
    }
  }, [location]);

  if (authState.session && !authState.isRefreshTokenExpired) return children;

  return (
    <Lottie
      style={{ height: "100vh", width: "100vw" }}
      animationData={OnBoardingJson}
      loop={true}
    />
  );
};

export default AuthWrapper;
