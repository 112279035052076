import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { AdAccountState } from "store/types/adaccount";
import { CreateVariantsState } from "store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import {
  AdSetItemType,
  AdsItemType,
  FacebookInitialState,
} from "store/types/CreativeAI_Revamp/facebook.types";
import { FBHomeScreenInitialStateType } from "store/types/CreativeAI_Revamp/HomeScreen/homeScreen.types";
import { mixPanelAdEvent } from "utils/mixpanel";

const useCaiFbMixPanel = () => {
  const [selectedVariants, setSelectedVariants] = useState([]);
  const [allVariants, setallVariants] = useState<any>([]);
  const [localDraftCount, setLocalDraftCount] = useState(0);
  const { adAccountsSelection, adSetSelection, adsSelection } = useSelector(
    (state: { fbHomeScreenReducer: FBHomeScreenInitialStateType }) =>
      state.fbHomeScreenReducer
  );
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { user } = useAuth0();
  const { selectedBrand } = adaccount;
  const { aiGroupList, adAccountList, adSetList, parentPlatformId } =
    useSelector(
      (state: { facebookReducer: FacebookInitialState }) =>
        state.facebookReducer
    );

  const { ads, adsOtherState, selectedAd, totalSelectedVariant } = useSelector(
    (state: { createVariantReducer: CreateVariantsState | any }) =>
      state.createVariantReducer
  );

  const { adsSelectionLogs, adSetSelectionLogs, adAccountsSelectionLogs } =
    useSelector((state: { fbActivityLogReducer: any }) => ({
      adsSelectionLogs: state.fbActivityLogReducer.adsSelection,
      adSetSelectionLogs: state.fbActivityLogReducer.adSetSelection,
      adAccountsSelectionLogs: state.fbActivityLogReducer.adAccountsSelection,
    }));

  const mixPanelAds = (items: AdsItemType[]) => {
    mixPanelAdEvent("CAI_Fb - Reference Ad Filtered", {
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      ai_group_name: aiGroupList.selectedAIGroup?.name,
      ai_group_id: aiGroupList.selectedAIGroup?.id,
      ad_account_id: adAccountsSelection?.selectedAdAccountId,
      ad_account_name: adAccountsSelection?.selection?.AdAccount?.name,
      ad_set_id: adSetSelection?.selectedAdSetId,
      ad_set_name: adSetSelection?.selection?.name,
      selected_reference_ad_id: selectedAd?.id,
      selected_reference_ad_name: selectedAd?.name,
      reference_ad_ids: items.map((item) => item?.id),
      reference_ad_names: items.map((item) => item?.name),
    });
  };
  const mixPanelAdAccount = (value: any) => {
    mixPanelAdEvent("CAI_Fb - Ad Account Filtered", {
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      ai_group_name: aiGroupList.selectedAIGroup?.name,
      ai_group_id: aiGroupList.selectedAIGroup?.id,
      ad_account_id: value.adAccountId,
      ad_account_name: value?.AdAccount?.name,
      // selected_reference_ad_id: selectedAd?.id,
      // selected_reference_ad_name: selectedAd?.name,
      // ad_set_id: adSetSelection?.selectedAdSetId,
      // ad_set_name: adSetSelection?.selection?.name,
    });
  };
  const mixPanelAdset = (value: AdSetItemType) => {
    mixPanelAdEvent("CAI_Fb - Ad Set Filtered", {
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      ai_group_name: aiGroupList.selectedAIGroup?.name,
      ai_group_id: aiGroupList.selectedAIGroup?.id,
      ad_account_id: adAccountsSelection?.selectedAdAccountId,
      ad_account_name: adAccountsSelection?.selection?.AdAccount?.name,
      ad_set_id: value?.adsetId,
      ad_set_name: value?.name,
      // selected_reference_ad_id: selectedAd?.id,
      // selected_reference_ad_name: selectedAd?.name,
    });
  };
  const minPanelBUFormSubmit = (
    selectedAiGroup: any,
    localBusinessUIData: any
  ) => {
    mixPanelAdEvent("CAI_Fb - BU Setup Saved", {
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      ai_group_name: aiGroupList.selectedAIGroup?.name,
      ai_group_id: aiGroupList.selectedAIGroup?.id,
      ad_account_id: adAccountsSelection?.selectedAdAccountId,
      ad_account_name: adAccountsSelection?.selection?.AdAccount?.name,
      ad_set_id: adSetSelection?.selectedAdSetId,
      ad_set_name: adSetSelection?.selection?.name,
      selected_ai_group_name: selectedAiGroup?.name,
      selected_ai_group_id: selectedAiGroup?.id,
      business_unit_name: localBusinessUIData?.businessUnit,
      selected_reference_ad_id: selectedAd?.id,
      selected_reference_ad_name: selectedAd?.name,
    });
  };
  const mixPanelAiGroup = (selection: any) => {
    mixPanelAdEvent("CAI_Fb - AI Group Selected", {
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      ai_group_name: selection.name,
      ai_group_id: selection.id,
    });
  };
  const mixPanelBuFormClick = (BUSINESS_UNIT_BY_ID_DATA: any) => {
    mixPanelAdEvent("CAI_Fb - BU Settings Button Used", {
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      ai_group_name: aiGroupList.selectedAIGroup?.name,
      ai_group_id: aiGroupList.selectedAIGroup?.id,
      ad_account_id: adAccountsSelection?.selectedAdAccountId,
      ad_account_name: adAccountsSelection?.selection?.AdAccount?.name,
      ad_set_id: adSetSelection?.selectedAdSetId,
      ad_set_name: adSetSelection?.selection?.name,
      business_unit_name: BUSINESS_UNIT_BY_ID_DATA?.businessUnit,
      selected_reference_ad_id: selectedAd?.id,
      selected_reference_ad_name: selectedAd?.name,
    });
  };
  const mixPanelFilterAdded = (filter_name: any, subFilterNames: any) => {
    mixPanelAdEvent("CAI_Fb - Filter Added", {
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      ad_account_id: adAccountsSelection?.selectedAdAccountId,
      ad_account_name: adAccountsSelection?.selection?.AdAccount?.name,
      ad_set_id: adSetSelection?.selectedAdSetId,
      ad_set_name: adSetSelection?.selection?.name,
      selected_reference_ad_id: selectedAd?.id,
      selected_reference_ad_name: selectedAd?.name,
      filter_name,
      "sub-filter_names": subFilterNames,
    });
  };

  const mixPanelFilterRemoved = (filter_name: any, subFilterNames: any) => {
    mixPanelAdEvent("CAI_Fb - Filter Removed", {
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      ad_account_id: adAccountsSelection?.selectedAdAccountId,
      ad_account_name: adAccountsSelection?.selection?.AdAccount?.name,
      ad_set_id: adSetSelection?.selectedAdSetId,
      ad_set_name: adSetSelection?.selection?.name,
      selected_reference_ad_id: selectedAd?.id,
      selected_reference_ad_name: selectedAd?.name,
      filter_name,
      "sub-filter_names": subFilterNames,
    });
  };
  const mixPanelAdSetDetailsViewed = () => {
    mixPanelAdEvent("CAI_Fb - Ad Set Details Viewed", {
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      ai_group_name: aiGroupList.selectedAIGroup?.name,
      ai_group_id: aiGroupList.selectedAIGroup?.id,
      ad_account_id: adAccountsSelection?.selectedAdAccountId,
      ad_account_name: adAccountsSelection?.selection?.AdAccount?.name,
      ad_set_id: adSetSelection?.selectedAdSetId,
      ad_set_name: adSetSelection?.selection?.name,
      selected_reference_ad_id: selectedAd?.id,
      selected_reference_ad_name: selectedAd?.name,
    });
  };
  const mixPanelCreateVariantsClicked = () => {
    const draftVariants = ads.find(
      (ad: any) => ad.id === selectedAd.id
    )?.draftVariants;
    mixPanelAdEvent("CAI_Fb - Create Variant Button Click", {
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      ai_group_name: aiGroupList.selectedAIGroup?.name,
      ai_group_id: aiGroupList.selectedAIGroup?.id,
      ad_account_id: adAccountsSelection?.selectedAdAccountId,
      ad_account_name: adAccountsSelection?.selection?.AdAccount?.name,
      ad_set_id: adSetSelection?.selectedAdSetId,
      ad_set_name: adSetSelection?.selection?.name,
      selected_reference_ad_id: selectedAd?.id,
      selected_reference_ad_name: selectedAd?.name,
      reference_ad_draft_state_variant_count: draftVariants,
    });
  };
  const mixPanelPreferredMethodSelected = (method: string) => {
    const draftVariants = ads.find(
      (ad: any) => ad.id === selectedAd.id
    )?.draftVariants;
    mixPanelAdEvent("CAI_Fb - Create Variant Mode Selected", {
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      ai_group_name: aiGroupList.selectedAIGroup?.name,
      ai_group_id: aiGroupList.selectedAIGroup?.id,
      ad_account_id: adAccountsSelection?.selectedAdAccountId,
      ad_account_name: adAccountsSelection?.selection?.AdAccount?.name,
      ad_set_id: adSetSelection?.selectedAdSetId,
      ad_set_name: adSetSelection?.selection?.name,
      selected_reference_ad_id: selectedAd?.id,
      selected_reference_ad_name: selectedAd?.name,
      reference_ad_draft_state_variant_count: draftVariants,
      create_variant_method: method,
      // module_name:
      //   method === "Universal Text Generator"
      //     ? "Universal Text Generator"
      //     : undefined,
    });
  };
  const mixPanelReferenceAdSelected = (adItem: any) => {
    const draftVariants = ads.find(
      (ad: any) => ad.id === adItem.id
    )?.draftVariants;
    mixPanelAdEvent("CAI_Fb - Reference Ad Card Selected", {
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      ai_group_name: aiGroupList.selectedAIGroup?.name,
      ai_group_id: aiGroupList.selectedAIGroup?.id,
      ad_account_id: adAccountsSelection?.selectedAdAccountId,
      ad_account_name: adAccountsSelection?.selection?.AdAccount?.name,
      ad_set_id: adSetSelection?.selectedAdSetId,
      ad_set_name: adSetSelection?.selection?.name,
      selected_reference_ad_id: adItem?.id,
      selected_reference_ad_name: adItem?.name,
      reference_ad_draft_state_variant_count: draftVariants,
    });
  };
  const mixPanelCloneVariant = (variantItem: any) => {
    const draftVariants = ads.find(
      (ad: any) => ad.id === selectedAd.id
    )?.draftVariants;
    mixPanelAdEvent("CAI_Fb - Ad Variant Cloned", {
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      ai_group_name: aiGroupList.selectedAIGroup?.name,
      ai_group_id: aiGroupList.selectedAIGroup?.id,
      ad_account_id: adAccountsSelection?.selectedAdAccountId,
      ad_account_name: adAccountsSelection?.selection?.AdAccount?.name,
      ad_set_id: adSetSelection?.selectedAdSetId,
      ad_set_name: adSetSelection?.selection?.name,
      selected_reference_ad_id: selectedAd?.id,
      selected_reference_ad_name: selectedAd?.name,
      reference_ad_draft_state_variant_count: draftVariants + 1,
      ad_variant_name: variantItem?.payload?.settings?.variantName,
      ad_variant_approval_state: variantItem?.reviewStatus ?? "NOT_RAISED",
    });
  };
  const mixPanelDeleteVariant = (variantItem: any) => {
    const draftVariants = ads.find(
      (ad: any) => ad.id === selectedAd.id
    )?.draftVariants;
    mixPanelAdEvent("CAI_Fb - Ad Variant Deleted", {
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      ai_group_name: aiGroupList.selectedAIGroup?.name,
      ai_group_id: aiGroupList.selectedAIGroup?.id,
      ad_account_id: adAccountsSelection?.selectedAdAccountId,
      ad_account_name: adAccountsSelection?.selection?.AdAccount?.name,
      ad_set_id: adSetSelection?.selectedAdSetId,
      ad_set_name: adSetSelection?.selection?.name,
      selected_reference_ad_id: selectedAd?.id,
      selected_reference_ad_name: selectedAd?.name,
      reference_ad_draft_state_variant_count: draftVariants,
      ad_variant_name: variantItem?.payload?.settings?.variantName,
      ad_variant_approval_state: variantItem?.reviewStatus ?? "NOT_RAISED",
      source: "Individual Delete",
    });
  };
  const mixPanelDeleteVariantsCTA = () => {
    const draftVariants = ads.find(
      (ad: any) => ad.id === selectedAd.id
    )?.draftVariants;
    let variants: any[] = [];
    ads.forEach((ad: any) => {
      if (ad?.variantList) {
        variants = [...variants, ...ad?.variantList];
      }
    });
    totalSelectedVariant.forEach((id: string) => {
      mixPanelAdEvent("CAI_Fb - Ad Variant Deleted", {
        brand_id: selectedBrand?.id,
        brand_name: selectedBrand?.name,
        brand_type: selectedBrand?.brandType,
        agency_id: selectedBrand?.agencyId,
        agency_name: selectedBrand?.agency_name,
        ai_group_name: aiGroupList.selectedAIGroup?.name,
        ai_group_id: aiGroupList.selectedAIGroup?.id,
        ad_account_id: adAccountsSelection?.selectedAdAccountId,
        ad_account_name: adAccountsSelection?.selection?.AdAccount?.name,
        ad_set_id: adSetSelection?.selectedAdSetId,
        ad_set_name: adSetSelection?.selection?.name,
        selected_reference_ad_id: selectedAd?.id,
        selected_reference_ad_name: selectedAd?.name,
        reference_ad_draft_state_variant_count: draftVariants,
        ad_variant_name: variants.find((item) => item.id === id)?.payload
          ?.settings?.variantName,
        ad_variant_approval_state:
          variants.find((item) => item.id === id)?.reviewStatus ?? "NOT_RAISED",
        source: "Floating CTA",
      });
    });
  };
  const mixPanelReview = (
    source: "Quick Review - Floating CTA" | "Review - Publish Modal"
  ) => {
    const draftVariants = ads.find(
      (ad: any) => ad.id === selectedAd.id
    )?.draftVariants;
    let variants: any[] = [];
    ads.forEach((ad: any) => {
      if (ad?.variantList) {
        variants = [...variants, ...ad?.variantList];
      }
    });
    setSelectedVariants(totalSelectedVariant);
    setallVariants(variants);
    setLocalDraftCount(draftVariants);
    totalSelectedVariant.forEach((id: string) => {
      mixPanelAdEvent("CAI_Fb - Ad Variant Submitted for review", {
        brand_id: selectedBrand?.id,
        brand_name: selectedBrand?.name,
        brand_type: selectedBrand?.brandType,
        agency_id: selectedBrand?.agencyId,
        agency_name: selectedBrand?.agency_name,
        ai_group_name: aiGroupList.selectedAIGroup?.name,
        ai_group_id: aiGroupList.selectedAIGroup?.id,
        ad_account_id: adAccountsSelection?.selectedAdAccountId,
        ad_account_name: adAccountsSelection?.selection?.AdAccount?.name,
        ad_set_id: adSetSelection?.selectedAdSetId,
        ad_set_name: adSetSelection?.selection?.name,
        selected_reference_ad_id: selectedAd?.id,
        selected_reference_ad_name: selectedAd?.name,
        reference_ad_draft_state_variant_count: draftVariants,
        ad_variant_name: variants.find((item) => item.id === id)?.payload
          ?.settings?.variantName,
        ad_variant_approval_state:
          variants.find((item) => item.id === id)?.reviewStatus ?? "NOT_RAISED",
        source,
      });
    });
  };
  const mixPanelPublish = () => {
    const draftVariants = ads.find(
      (ad: any) => ad.id === selectedAd.id
    )?.draftVariants;
    let variants: any[] = [];
    ads.forEach((ad: any) => {
      if (ad?.variantList) {
        variants = [...variants, ...ad?.variantList];
      }
    });
    totalSelectedVariant.forEach((id: string) => {
      mixPanelAdEvent("CAI_Fb - Ad Variant Published", {
        brand_id: selectedBrand?.id,
        brand_name: selectedBrand?.name,
        brand_type: selectedBrand?.brandType,
        agency_id: selectedBrand?.agencyId,
        agency_name: selectedBrand?.agency_name,
        ai_group_name: aiGroupList.selectedAIGroup?.name,
        ai_group_id: aiGroupList.selectedAIGroup?.id,
        ad_account_id: adAccountsSelection?.selectedAdAccountId,
        ad_account_name: adAccountsSelection?.selection?.AdAccount?.name,
        ad_set_id: adSetSelection?.selectedAdSetId,
        ad_set_name: adSetSelection?.selection?.name,
        selected_reference_ad_id: selectedAd?.id,
        selected_reference_ad_name: selectedAd?.name,
        reference_ad_draft_state_variant_count: draftVariants,
        ad_variant_name: variants.find((item) => item.id === id)?.payload
          ?.settings?.variantName,
        ad_variant_approval_state:
          variants.find((item) => item.id === id)?.reviewStatus ?? "NOT_RAISED",
      });
    });
  };
  const mixPanelReviewLinkCopied = () => {
    console.log("called", selectedVariants, allVariants);
    const draftVariants = ads.find(
      (ad: any) => ad.id === selectedAd.id
    )?.draftVariants;
    selectedVariants.forEach((id: string) => {
      mixPanelAdEvent("CAI_Fb - Review Link Copied", {
        brand_id: selectedBrand?.id,
        brand_name: selectedBrand?.name,
        brand_type: selectedBrand?.brandType,
        agency_id: selectedBrand?.agencyId,
        agency_name: selectedBrand?.agency_name,
        ai_group_name: aiGroupList.selectedAIGroup?.name,
        ai_group_id: aiGroupList.selectedAIGroup?.id,
        ad_account_id: adAccountsSelection?.selectedAdAccountId,
        ad_account_name: adAccountsSelection?.selection?.AdAccount?.name,
        ad_set_id: adSetSelection?.selectedAdSetId,
        ad_set_name: adSetSelection?.selection?.name,
        selected_reference_ad_id: selectedAd?.id,
        selected_reference_ad_name: selectedAd?.name,
        reference_ad_draft_state_variant_count: localDraftCount,
        ad_variant_name: allVariants.find((item: any) => item.id === id)
          ?.payload?.settings?.variantName,
        ad_variant_approval_state:
          allVariants.find((item: any) => item.id === id)?.reviewStatus ??
          "NOT_RAISED",
      });
      console.log("called end");
    });
  };
  const mixPanelContactUsClicked = () => {
    mixPanelAdEvent("CAI_Fb - Contact us Button Used", {
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      ai_group_name: aiGroupList.selectedAIGroup?.name,
      ai_group_id: aiGroupList.selectedAIGroup?.id,
      ad_account_id: adAccountsSelection?.selectedAdAccountId,
      ad_account_name: adAccountsSelection?.selection?.AdAccount?.name,
      ad_set_id: adSetSelection?.selectedAdSetId,
      ad_set_name: adSetSelection?.selection?.name,
      selected_reference_ad_id: selectedAd?.id,
      selected_reference_ad_name: selectedAd?.name,
    });
  };
  const mixPanelPageViewed = (page_name: "Home Page" | "Activity Log") => {
    setTimeout(() => {
      mixPanelAdEvent("Page View - Dashboard", {
        brand_name: selectedBrand?.name,
        brand_type: selectedBrand?.brandType,
        agency_id: selectedBrand?.agencyId,
        agency_name: selectedBrand?.agency_name,
        module_name: "CAI - FB",
        sub_module_name: page_name,
        email: user?.email,
        username: user?.name,
      });
    }, 1000);
  };
  const mixPanelLogsExported = (date_range_selected: string) => {
    mixPanelAdEvent("CAI_Fb - Export Button Used", {
      ai_group_name: aiGroupList.selectedAIGroup?.name,
      ai_group_id: aiGroupList.selectedAIGroup?.id,
      ad_account_id: adAccountsSelectionLogs?.selectedAdAccountId,
      ad_account_name: adAccountsSelectionLogs?.selection?.AdAccount?.name,
      ad_set_id: adSetSelectionLogs?.selectedAdSets?.map(
        (item: any) => item.id
      ),
      ad_set_name: adSetSelectionLogs?.selectedAdSets?.map(
        (item: any) => item.name
      ),
      reference_ad_ids: adsSelectionLogs?.selectedAds?.map(
        (item: any) => item.id
      ),
      reference_ad_names: adsSelectionLogs?.selectedAds?.map(
        (item: any) => item.name
      ),
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      date_range_selected,
    });
  };
  //Creative Studio
  const mixPanelCreativeStudioSeeMore = (
    variantItem: any,
    creative_studio_section_name: string
  ) => {
    const draftVariants = ads.find(
      (ad: any) => ad.id === selectedAd.id
    )?.draftVariants;
    mixPanelAdEvent("CAI_Fb - See More Recommendations Used", {
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      ai_group_name: aiGroupList.selectedAIGroup?.name,
      ai_group_id: aiGroupList.selectedAIGroup?.id,
      ad_account_id: adAccountsSelection?.selectedAdAccountId,
      ad_account_name: adAccountsSelection?.selection?.AdAccount?.name,
      ad_set_id: adSetSelection?.selectedAdSetId,
      ad_set_name: adSetSelection?.selection?.name,
      selected_reference_ad_id: selectedAd?.id,
      selected_reference_ad_name: selectedAd?.name,
      reference_ad_draft_state_variant_count: draftVariants,
      ad_variant_name: variantItem?.payload?.settings?.variantName,
      ad_variant_approval_state: variantItem?.reviewStatus ?? "NOT_RAISED",
      creative_studio_section_name,
    });
  };
  const mixPanelCreativeStudioAdSettingButtonClick = (
    variantItem: any,
    creative_studio_section_name: string,
    ad_name_edited: boolean,
    url_edited: boolean,
    display_url_edited: boolean
  ) => {
    const draftVariants = ads.find(
      (ad: any) => ad.id === selectedAd.id
    )?.draftVariants;
    mixPanelAdEvent("CAI_Fb - Ad Setting Button Click", {
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      ai_group_name: aiGroupList.selectedAIGroup?.name,
      ai_group_id: aiGroupList.selectedAIGroup?.id,
      ad_account_id: adAccountsSelection?.selectedAdAccountId,
      ad_account_name: adAccountsSelection?.selection?.AdAccount?.name,
      ad_set_id: adSetSelection?.selectedAdSetId,
      ad_set_name: adSetSelection?.selection?.name,
      selected_reference_ad_id: selectedAd?.id,
      selected_reference_ad_name: selectedAd?.name,
      reference_ad_draft_state_variant_count: draftVariants,
      ad_variant_name: variantItem?.payload?.settings?.variantName,
      ad_variant_approval_state: variantItem?.reviewStatus ?? "NOT_RAISED",
      creative_studio_section_name,
      ad_name_edited,
      url_edited,
      display_url_edited,
    });
  };
  const mixPanelCreativeStudioRenderUpdate = (variantItem: any) => {
    const draftVariants = ads.find(
      (ad: any) => ad.id === selectedAd.id
    )?.draftVariants;
    mixPanelAdEvent("CAI_Fb - Video Render & Update Used", {
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      ai_group_name: aiGroupList.selectedAIGroup?.name,
      ai_group_id: aiGroupList.selectedAIGroup?.id,
      ad_account_id: adAccountsSelection?.selectedAdAccountId,
      ad_account_name: adAccountsSelection?.selection?.AdAccount?.name,
      ad_set_id: adSetSelection?.selectedAdSetId,
      ad_set_name: adSetSelection?.selection?.name,
      selected_reference_ad_id: selectedAd?.id,
      selected_reference_ad_name: selectedAd?.name,
      reference_ad_draft_state_variant_count: draftVariants,
      ad_variant_name: variantItem?.payload?.settings?.variantName,
      ad_variant_approval_state: variantItem?.reviewStatus ?? "NOT_RAISED",
      creative_studio_section_name: "video",
      button_click: "Edit",
    });
  };
  const mixPanelCreativeStudioOriginalAdDetailsClicked = (
    variantItem: any,
    creative_studio_section_name: string | undefined
  ) => {
    const draftVariants = ads.find(
      (ad: any) => ad.id === selectedAd.id
    )?.draftVariants;
    mixPanelAdEvent("CAI_Fb - Original Ad Details Button Used", {
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      ai_group_name: aiGroupList.selectedAIGroup?.name,
      ai_group_id: aiGroupList.selectedAIGroup?.id,
      ad_account_id: adAccountsSelection?.selectedAdAccountId,
      ad_account_name: adAccountsSelection?.selection?.AdAccount?.name,
      ad_set_id: adSetSelection?.selectedAdSetId,
      ad_set_name: adSetSelection?.selection?.name,
      selected_reference_ad_id: selectedAd?.id,
      selected_reference_ad_name: selectedAd?.name,
      reference_ad_draft_state_variant_count: draftVariants,
      ad_variant_name: variantItem?.payload?.settings?.variantName,
      ad_variant_approval_state: variantItem?.reviewStatus ?? "NOT_RAISED",
      creative_studio_section_name,
    });
  };
  const mixPanelCreativeStudioConfigureClicked = (
    variantItem: any,
    business_unit_name: any,
    display_brand_name: any,
    interest_keywords: any,
    headline_checked: any,
    description_checked: any,
    primary_text_checked: any,
    media_checked: any,
    type: "image_checked" | "video_checked"
  ) => {
    const draftVariants = ads.find(
      (ad: any) => ad.id === selectedAd.id
    )?.draftVariants;
    mixPanelAdEvent("CAI_Fb - Configure Recommendation Button Used", {
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      ai_group_name: aiGroupList.selectedAIGroup?.name,
      ai_group_id: aiGroupList.selectedAIGroup?.id,
      ad_account_id: adAccountsSelection?.selectedAdAccountId,
      ad_account_name: adAccountsSelection?.selection?.AdAccount?.name,
      ad_set_id: adSetSelection?.selectedAdSetId,
      ad_set_name: adSetSelection?.selection?.name,
      selected_reference_ad_id: selectedAd?.id,
      selected_reference_ad_name: selectedAd?.name,
      reference_ad_draft_state_variant_count: draftVariants,
      ad_variant_name: variantItem?.payload?.settings?.variantName,
      ad_variant_approval_state: variantItem?.reviewStatus ?? "NOT_RAISED",
      business_unit_name,
      display_brand_name,
      interest_keywords,
      headline_checked,
      description_checked,
      primary_text_checked,
      [type]: media_checked,
    });
  };
  const mixPanelCreativeStudioConfigureGenerateClicked = (
    variantItem: any,
    business_unit_name: any,
    display_brand_name: any,
    interest_keywords: any,
    headline_checked: any,
    description_checked: any,
    primary_text_checked: any,
    media_checked: any,
    type: "image_checked" | "video_checked"
  ) => {
    const draftVariants = ads.find(
      (ad: any) => ad.id === selectedAd.id
    )?.draftVariants;
    mixPanelAdEvent("CAI_Fb - Configure Recommendation Generate Button Used", {
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      ai_group_name: aiGroupList.selectedAIGroup?.name,
      ai_group_id: aiGroupList.selectedAIGroup?.id,
      ad_account_id: adAccountsSelection?.selectedAdAccountId,
      ad_account_name: adAccountsSelection?.selection?.AdAccount?.name,
      ad_set_id: adSetSelection?.selectedAdSetId,
      ad_set_name: adSetSelection?.selection?.name,
      selected_reference_ad_id: selectedAd?.id,
      selected_reference_ad_name: selectedAd?.name,
      reference_ad_draft_state_variant_count: draftVariants,
      ad_variant_name: variantItem?.payload?.settings?.variantName,
      ad_variant_approval_state: variantItem?.reviewStatus ?? "NOT_RAISED",
      business_unit_name,
      display_brand_name,
      interest_keywords,
      headline_checked,
      description_checked,
      primary_text_checked,
      [type]: media_checked,
    });
  };

  const mixPanelEditVariant = () => {
    const draftVariants = ads.find(
      (ad: any) => ad.id === selectedAd.id
    )?.draftVariants;
    mixPanelAdEvent("CAI_Fb - Ad Variant Edit Clicked", {
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      ai_group_name: aiGroupList.selectedAIGroup?.name,
      ai_group_id: aiGroupList.selectedAIGroup?.id,
      ad_account_id: adAccountsSelection?.selectedAdAccountId,
      ad_account_name: adAccountsSelection?.selection?.AdAccount?.name,
      ad_set_id: adSetSelection?.selectedAdSetId,
      ad_set_name: adSetSelection?.selection?.name,
      selected_reference_ad_id: selectedAd?.id,
      selected_reference_ad_name: selectedAd?.name,
      reference_ad_draft_state_variant_count: draftVariants,
    });
  };

  const mixPanelUpdateVariant = () => {
    const draftVariants = ads.find(
      (ad: any) => ad.id === selectedAd.id
    )?.draftVariants;
    mixPanelAdEvent("CAI_Fb - Ad Variant Updated", {
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      ai_group_name: aiGroupList.selectedAIGroup?.name,
      ai_group_id: aiGroupList.selectedAIGroup?.id,
      ad_account_id: adAccountsSelection?.selectedAdAccountId,
      ad_account_name: adAccountsSelection?.selection?.AdAccount?.name,
      ad_set_id: adSetSelection?.selectedAdSetId,
      ad_set_name: adSetSelection?.selection?.name,
      selected_reference_ad_id: selectedAd?.id,
      selected_reference_ad_name: selectedAd?.name,
      reference_ad_draft_state_variant_count: draftVariants,
    });
  };

  const mixPanelSeeMoreRecommendation = () => {
    mixPanelAdEvent("CAI_Fb - See More Recommendations Clicked", {
      brand_id: selectedBrand?.id,
      brand_name: selectedBrand?.name,
      brand_type: selectedBrand?.brandType,
      agency_id: selectedBrand?.agencyId,
      agency_name: selectedBrand?.agency_name,
      ai_group_name: aiGroupList.selectedAIGroup?.name,
      ai_group_id: aiGroupList.selectedAIGroup?.id,
      ad_account_id: adAccountsSelection?.selectedAdAccountId,
      ad_account_name: adAccountsSelection?.selection?.AdAccount?.name,
      ad_set_id: adSetSelection?.selectedAdSetId,
      ad_set_name: adSetSelection?.selection?.name,
      selected_reference_ad_id: selectedAd?.id,
      selected_reference_ad_name: selectedAd?.name,
      creative_studio_section_name: "video",
    });
  };

  return {
    mixPanelAdAccount,
    mixPanelAdset,
    mixPanelAds,
    minPanelBUFormSubmit,
    mixPanelAiGroup,
    mixPanelBuFormClick,
    mixPanelFilterAdded,
    mixPanelFilterRemoved,
    mixPanelAdSetDetailsViewed,
    mixPanelCreateVariantsClicked,
    mixPanelPreferredMethodSelected,
    mixPanelReferenceAdSelected,
    mixPanelCloneVariant,
    mixPanelDeleteVariant,
    mixPanelDeleteVariantsCTA,
    mixPanelReview,
    mixPanelPublish,
    mixPanelReviewLinkCopied,
    mixPanelContactUsClicked,
    mixPanelPageViewed,
    mixPanelLogsExported,
    mixPanelCreativeStudioSeeMore,
    mixPanelCreativeStudioAdSettingButtonClick,
    mixPanelCreativeStudioRenderUpdate,
    mixPanelCreativeStudioOriginalAdDetailsClicked,
    mixPanelCreativeStudioConfigureClicked,
    mixPanelCreativeStudioConfigureGenerateClicked,
    mixPanelEditVariant,
    mixPanelUpdateVariant,
    mixPanelSeeMoreRecommendation,
  };
};
export { useCaiFbMixPanel };
