import { StyledText } from "components/shared";
import {
  StyledCreativeAIGroupSettings,
  StyledCreativeAIHeader,
  StyledCreativeAIMainBox,
  StyledCreativeAITabBox,
  StyledTabItem,
  StyledTabSVGContainer,
} from "./styled";
import { ReactComponent as Home } from "../../../assets/cai/svg/home.svg";
import { ReactComponent as Log } from "../../../assets/cai/svg/log.svg";
import { ReactComponent as Settings } from "../../../assets/cai/svg/settings.svg";

import { Box } from "@mui/system";
import HomeScreen from "components/CreativeAI_Revamp/Facebook/HomeScreen/HomeScreen";
import { useEffect, useMemo, useState } from "react";
import { MemoizedLogScreen } from "components/CreativeAI_Revamp/Facebook/LogScreen/LogScreen";
import { PxSelect, TextWithTooltip } from "components/CreativeAI_Revamp/shared";
import { useDispatch, useSelector } from "react-redux";
import {
  getAIGroups,
  setAIGroups,
} from "store/actions/CreativeAI_Revamp/facebook.actions";
import { AdAccountState } from "store/types/adaccount";
import { FacebookInitialState } from "store/types/CreativeAI_Revamp/facebook.types";
import { CircularProgress, IconButton, Skeleton } from "@mui/material";
import CreateVariantModal from "components/CreativeAI/Facebook/Main/CreateVariantModal";
import { getBusinessUintById } from "store/actions/CreativeAI/Facebook/Main/Main";
import {
  GET_ELEMENT_RESULT_METRICS,
  SET_BUSINESS_UNIT_WITH_ID,
  SET_ELEMENT_CREATIVE_AI,
} from "store/types/CreativeAI/Facebook/Main/Main";
import { useCaiFbMixPanel } from "components/CreativeAI_Revamp/Facebook/HomeScreen/hooks/useCaiFbMixPanel";
import { useAuth0 } from "@auth0/auth0-react";

export default function Facebook() {
  // local states
  const [tabValue, setTabValue] = useState<"home" | "log">("home");
  const [searchAIGroup, setSearchAIGroup] = useState("");
  const [showUnitForm, setShowUnitForm] = useState(false);
  // const [showBUModal, setShowBUModal] = useState<boolean>(true);

  // local  imports
  const dispatch = useDispatch();
  const { user } = useAuth0();

  // redux states
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );

  const { aiGroupList } = useSelector(
    (state: { facebookReducer: FacebookInitialState }) => state.facebookReducer
  );

  const { selectedBrand, platforms } = adaccount;

  const { CREATIVE_ELEMENT_AI, BUSINESS_UNIT_BY_ID_DATA } = useSelector(
    (state: { selectCohortReducer: any; MainReducer: any }) => ({
      BUSINESS_UNIT_BY_ID_DATA: state.MainReducer.variantWithId,
      CREATIVE_ELEMENT_AI: state.MainReducer.elementCreativeAi,
    })
  );

  // function local

  const renderComponent = (value: unknown) => {
    return (
      <TextWithTooltip
        fontSize="0.75em"
        lineHeight="20px"
        fontWeight={600}
        text={aiGroupList?.selectedAIGroup?.name ?? ""}
        boxProps={{
          width: "100%",
          textAlign: "center",
          padding: "0em .5em",
        }}
      />
    );
  };

  useEffect(() => {
    dispatch({ type: "GENERATE_LEVERS" });
  }, []);

  useEffect(() => {
    if (user) {
      mixPanelPageViewed(tabValue === "home" ? "Home Page" : "Activity Log");
    }
  }, [tabValue, user]);

  useEffect(() => {
    if (
      aiGroupList?.selectedAIGroup &&
      aiGroupList?.selectedAIGroup?.Elements?.[0]?.id
    ) {
      dispatch({
        type: "GET_ELEMENT_CREATIVE_AI",
        payload: {
          elementId: aiGroupList?.selectedAIGroup?.Elements?.[0]?.id,
        },
      });
      dispatch({
        type: GET_ELEMENT_RESULT_METRICS,
        payload: {
          elementId: aiGroupList?.selectedAIGroup?.Elements?.[0]?.id,
        },
      });
    } else if (aiGroupList?.selectedAIGroup) {
      dispatch({
        type: SET_ELEMENT_CREATIVE_AI,
        payload: {
          data: {},
        },
      });
    } else {
      dispatch({
        type: SET_ELEMENT_CREATIVE_AI,
        payload: {
          data: {},
          loading: true,
        },
      });
    }
  }, [aiGroupList?.selectedAIGroup]);

  useEffect(() => {
    if (CREATIVE_ELEMENT_AI?.data?.businessUnitId) {
      dispatch(
        getBusinessUintById({
          businessUnitId: CREATIVE_ELEMENT_AI?.data?.businessUnitId,
        })
      );
    } else {
      dispatch({ type: SET_BUSINESS_UNIT_WITH_ID, payload: {} });
    }
  }, [CREATIVE_ELEMENT_AI?.data?.businessUnitId]);

  useEffect(() => {
    if (selectedBrand.id && platforms.length > 0) {
      fetchAiGroups(""); // no search
    }
  }, [selectedBrand.id, platforms.length]);

  const fetchAiGroups = (search?: string, isSearching?: boolean) => {
    dispatch(
      getAIGroups({
        brandId: selectedBrand.id,
        isSearching,
        params: {
          responseFields: "name,id",
          limit: 600, // will remove it once testing
          // hasBusinessUnit: true,
          parentPlatformId: platforms.find(
            (item) => item.type.toUpperCase() === "FACEBOOK AD"
          )?.parentPlatformId,
          search,
        },
      })
    );
  };

  // hooks
  const displayAigroupsList = useMemo(() => {
    if (searchAIGroup.length > 0) {
      return aiGroupList.data.filter(
        (option) =>
          option.name.toLowerCase().indexOf(searchAIGroup.toLowerCase()) > -1 ||
          option.id.toLowerCase().indexOf(searchAIGroup.toLowerCase()) > -1
      );
    } else {
      return aiGroupList.data;
    }
  }, [searchAIGroup, aiGroupList.data]);
  const { mixPanelAiGroup, mixPanelBuFormClick, mixPanelPageViewed } =
    useCaiFbMixPanel();
  return (
    <StyledCreativeAIMainBox>
      <StyledCreativeAIHeader>
        <StyledText
          fontSize="1.5625em"
          lineHeight="1.875em"
          fontWeight={700}
          fontFamily="Lato"
        >
          Creative AI
        </StyledText>
        <StyledCreativeAITabBox>
          <StyledTabItem
            active={tabValue === "home"}
            onClick={() => setTabValue("home")}
          >
            <StyledTabSVGContainer>
              <Home width="100%" height="100%" />
            </StyledTabSVGContainer>
          </StyledTabItem>
          <Box mr="1em" />
          <StyledTabItem
            active={tabValue === "log"}
            onClick={() => setTabValue("log")}
          >
            <StyledTabSVGContainer>
              <Log width="100%" height="100%" />
            </StyledTabSVGContainer>
          </StyledTabItem>
        </StyledCreativeAITabBox>
        <Box display="flex" alignItems="center" height="100%">
          <StyledText
            fontSize="0.875em"
            lineHeight="1.425em"
            fontWeight={600}
            fontFamily="Inter"
            paddingRight={"0.86em"}
            color="#666666"
          >
            AI Group
          </StyledText>
          {aiGroupList.isLoading ? (
            <Skeleton
              width="10em"
              height="3.5em"
              sx={{
                borderRadius: "8px",
              }}
            />
          ) : (
            <>
              <PxSelect
                options={displayAigroupsList}
                selection={aiGroupList.selection}
                handlePropChange={(selection) => {
                  dispatch(
                    setAIGroups({
                      ...aiGroupList,
                      selection,
                      selectedAIGroup: selection,
                    })
                  );
                  mixPanelAiGroup(selection);
                }}
                dropDownContainerWidth="7.5625em"
                dropDownContainerHeight="2.5em"
                sx={{
                  borderWidth: "1px 0px 1px 1px",
                  borderStyle: "solid",
                  borderColor: "#DDDDDD",
                  borderRadius: "8px 0px 0px 8px",
                  fontSize: "1em",
                }}
                hideSubtitle
                titleSXProps={{
                  fontSize: "0.625em",
                  lineHeight: "12px",
                  fontWeight: 400,
                }}
                renderComponent={renderComponent}
                titleKey="name"
                subTitleKey="id"
                onSearch={(value) => setSearchAIGroup(value ?? "")}
                selectLoading={aiGroupList.isSearching}
                onClose={() => {
                  setSearchAIGroup("");
                }}
              />
              <StyledCreativeAIGroupSettings>
                {/* {CREATIVE_ELEMENT_AI.loading ? (
                <CircularProgress size="1.2em" thickness={6} />
              ) : ( */}
                <IconButton
                  onClick={() => {
                    setShowUnitForm(true);
                    mixPanelBuFormClick(BUSINESS_UNIT_BY_ID_DATA);
                  }}
                >
                  <Settings />
                </IconButton>
                {/* )} */}
                {/* <BusinessUnitModalSettings /> */}
                {showUnitForm && (
                  <CreateVariantModal
                    onHide={() => {
                      setShowUnitForm(false);
                    }}
                    variantModalType="Setting"
                    setShowUniteForm={setShowUnitForm}
                    showUnitForm={showUnitForm}
                  />
                )}
              </StyledCreativeAIGroupSettings>
            </>
          )}
        </Box>
      </StyledCreativeAIHeader>
      <HomeScreen selectedTabValue={tabValue} />
      <MemoizedLogScreen selectedTabValue={tabValue} />
    </StyledCreativeAIMainBox>
  );
}
