import "./styles.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Lottie from "lottie-react";
import OnBoardingJson from "../../assets/json/OnBoarding.json";
import {
  getSearchParams,
  updateSearchParams,
} from "../../utils/commonFunctions";

import { AdAccountState } from "store/types/adaccount";
import { getAccountsStats, setTokenStats } from "store/actions/accountsManager";
import {
  getAllBrands,
  setActiveBrand,
  setBrandLoading,
  setBrands,
} from "../../store/actions/adaccount";

import { initialState } from "store/types/auth";
import { mixPanelAdEvent } from "../../utils/mixpanel";
import { useAuth0 } from "@auth0/auth0-react";
import { resetLoginRedirect } from "store/actions/auth";
import mixpanel from "mixpanel-browser";

type Props = {
  render: () => React.ReactNode;
};

function TMSWrapper({ render }: Props) {
  const [fetched, setFetched] = useState<boolean>(false);

  const { user } = useAuth0();
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const authState = useSelector((state: { auth: initialState }) => state.auth);

  const {
    selectedBrand: {
      id: brand_id = "",
      name: brand_name,
      agency_name,
      agencyId,
    } = {},
  } = adaccount;
  const dispatch = useDispatch();
  const history = useHistory();
  const { 0: actionParam } = useParams<{ 0: string }>();
  const action = actionParam.substring(1);
  useEffect(() => {
    if (action !== "logout") {
      dispatch(setBrandLoading(true));

      dispatch(
        getAllBrands(
          { user: authState.user },
          (response: any, error: boolean) => {
            if (!error && response?.data?.length > 0) {
              const { data } = response;
              const brandId = getSearchParams("brand_id");
              let brand = data[0];
              if (brandId) {
                brand = data.find((g: any) => g.id === brandId);
              }
              updateSearchParams("brand_id", brand?.id);
              dispatch(setActiveBrand(brand));
              dispatch(setBrands(data));
              dispatch(setBrandLoading(false));
            }
          }
        )
      );
    }
  }, []);

  useEffect(() => {
    if (brand_id && action !== "logout") {
      dispatch(
        getAccountsStats({ brandId: brand_id }, (response: any, error: any) => {
          setFetched(true);
          if (
            !error &&
            response.data &&
            response.data.adAccounts &&
            response.data.adAccounts.length > 0
          ) {
            dispatch(setTokenStats(response.data.adAccounts));
          } else {
            dispatch(setTokenStats([]));
          }
          if (authState.loginRedirect) {
            mixpanel.identify(user?.email);
            mixPanelAdEvent("Login Successful", {
              email: user?.email,
              username: user?.name,
              brand_name,
              brand_id: brand_id,
              agency_name,
              agency_id: agencyId,
              source: "Dashboard",
            });
            dispatch(resetLoginRedirect());
          }

          if (authState.loginRedirect || !action) {
            history.push(`/singlePlatform?brand_id=${brand_id}`);
          }
        })
      );
    }
  }, [brand_id]);
  const containerRef = React.useRef(null);

  return (
    <div ref={containerRef}>
      {!fetched || action === "logout" ? (
        <Lottie
          style={{ height: "100vh", width: "100vw" }}
          animationData={OnBoardingJson}
          loop={true}
        />
      ) : (
        render()
      )}
    </div>
  );
}

export default TMSWrapper;
