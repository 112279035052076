import { Box } from "@mui/material";
import React from "react";
import { StyledPerformanceText } from "./styled";
import { ReactComponent as CustomerIcon } from "../../../../../assets/cai/svg/customer.svg";
import { ReactComponent as BrandIcon } from "../../../../../assets/cai/svg/brand.svg";
import { ReactComponent as BenifitIcon } from "../../../../../assets/cai/svg/benifit.svg";
import { ReactComponent as OfferIcon } from "../../../../../assets/cai/svg/offer.svg";
import { PerformanceScoreType } from "store/types/CreativeAI_Revamp/HomeScreen/Editor/editor.types";

import { ReactComponent as ProductIcon } from "../../../../../assets/cai/svg/product.svg"
import { ReactComponent as CallToActionIcon } from "../../../../../assets/cai/svg/cursorclick.svg";
import { ReactComponent as UniqueSellingPropositionIcon } from "../../../../../assets/cai/svg/ranking.svg";
import { ReactComponent as ProblemSolutionIcon } from "../../../../../assets/cai/svg/puzzle.svg";
import { ReactComponent as SocialProofIcon } from "../../../../../assets/cai/svg/comment.svg";
import { ReactComponent as DiscountIcon } from "../../../../../assets/cai/svg/discount.svg";
import { ReactComponent as SuitcaseIcon } from "../../../../../assets/cai/svg/suitcase.svg";

export default function PerformanceScoreInfo({
  handlePerformancePopoverOpen,
  handlePerformancePopoverClose,
  infoItem,
}: {
  handlePerformancePopoverOpen: any;
  handlePerformancePopoverClose: any;
  infoItem: PerformanceScoreType;
}) {
  const performanceObjectMapping: any = {
    "Medium Performance": {
      textColor: "#F99F35",
      bgColor: "rgba(255, 240, 222, 0.8)",
      text: "MEDIUM",
    },
    "High Performance": {
      textColor: "#0869FB",
      bgColor: "#EDF4FF",
      text: "HIGH",
    },
    "Low Performance": {
      textColor: "#E84B47",
      bgColor: "#FFF0DECC",
      text: "LOW",
    },
    No_Text: {
      textColor: "#9798A1",
      bgColor: "#EAEAEC",
      text: "-",
    },
  };

  const newPerformanceScoreColorMapping = () => {
    if(typeof infoItem.performance_score === 'number'){
      if(infoItem.performance_score<3){
        return performanceObjectMapping["Low Performance"]
     } else if(infoItem.performance_score>=3 && infoItem.performance_score<=7){
        return performanceObjectMapping["Medium Performance"]
     } else{
        return performanceObjectMapping["High Performance"];
     }
    }else{
        return performanceObjectMapping["No_Text"];
    }
    
  }

    
  function hasClassification(infoItem: { tags?: Record<string, { classification?: boolean }> }): boolean {
    return Object.keys(infoItem?.tags || {}).some(tag => {
      const tagData = infoItem?.tags && infoItem.tags[tag];
      return tagData && tagData.classification === true;
    });
  }

  const isKeyHighlights =
    infoItem?.offer_label ||
    infoItem?.brand_centric ||
    infoItem?.benefit_label ||
    infoItem?.customer_centric;

  const isNewPerformanceScoreType = infoItem?.tags !== undefined && hasClassification(infoItem) ;

  interface PerformanceTagIcons {
    "Product Centric": JSX.Element;
    "Brand Centric": JSX.Element;
    "Call-to-Action": JSX.Element;
    "Unique Selling Proposition": JSX.Element;
    "Customer Centric": JSX.Element;
    "Benefit Based": JSX.Element;
    "Offer Based": JSX.Element;
    "Problem-Solution Structure": JSX.Element;
    "Social Proof": JSX.Element;
  }

  const performanceTagIcons:PerformanceTagIcons = {
    "Product Centric": <ProductIcon />,
    "Brand Centric": <BrandIcon />,
    "Call-to-Action": <CallToActionIcon />,
    "Unique Selling Proposition": <UniqueSellingPropositionIcon />,
    "Customer Centric": <CustomerIcon />,
    "Benefit Based": <BenifitIcon />,
    "Offer Based": <OfferIcon />,
    "Problem-Solution Structure": <ProblemSolutionIcon />,
    "Social Proof": <SocialProofIcon />,
  };

  return (
    <Box
      display="flex"
      sx={{
        padding: isKeyHighlights
          ? "0.2em .2em 0.2em 1em"
          : "0.4em .5em 0.4em 1em",
        background:
        performanceObjectMapping?.[infoItem?.performance_labels]?.bgColor,
        borderRadius: "8px",
      }}
      alignItems="center"
      alignSelf="flex-start"
      onMouseEnter={handlePerformancePopoverOpen}
      onMouseLeave={handlePerformancePopoverClose}
    >
      <StyledPerformanceText
        color={
          performanceObjectMapping?.[infoItem?.performance_labels]?.textColor
        }
      >
        Performance Score:{" "}
        {infoItem?.performance_score && `${infoItem?.performance_score}/10`}
      </StyledPerformanceText>
        {isNewPerformanceScoreType && (
            <Box
            display="flex"
            ml=".5em"
            sx={{
              background: "#FFFFFF",
              borderRadius: "6px 0px",
              gap: ".4em",
              padding: "0.2em .5em",
            }}
            alignItems="center">
            {
            infoItem?.tags &&  Object.keys(infoItem?.tags).map((tag) => {
                const tagData = infoItem.tags && infoItem?.tags[tag];
                if(tagData && tagData.classification){
                  return performanceTagIcons[tag as keyof PerformanceTagIcons];
                }
              })
            }
          </Box>
          )}
      {!isNewPerformanceScoreType &&isKeyHighlights && (
        <Box
          display="flex"
          ml=".5em"
          sx={{
            background: "#FFFFFF",
            borderRadius: "6px 0px",
            gap: ".4em",
            padding: "0.2em .5em",
          }}
          alignItems="center"
        >
          {infoItem?.offer_label && <OfferIcon />}
          {infoItem?.brand_centric && <BrandIcon />}
          {infoItem?.benefit_label && <BenifitIcon />}
          {infoItem?.customer_centric && <CustomerIcon />}
        </Box>
      )}
    </Box>
  );
}
