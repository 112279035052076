import { Box, IconButton, RadioGroup } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateRecommendationMetadata } from "../../../../../../store/actions/CreativeAI/Facebook/CreateVariant/createVariant";
import {
  CreateVariantsState,
  RecommendationMetaDataItem,
  UPDATE_RECOMMENDATION_SELECTION_STATE,
} from "../../../../../../store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import EditableTextRadio from "../EditableTextRadio/EditableTextRadio";
import { HashType } from "../Editor.types";
import {
  StyledButtonDiv,
  StyledIntroText,
  StyledOptionsContainer,
  StyledShowMore,
  StyledNewIcon,
  StyledRecommendationTitle,
} from "./styled";
import { Fragment, useState } from "react";
import { ReactComponent as NewIcon } from "../../../../../../../src/assets/svg/new.svg";
import NoRecommendations from "./NoRecommendations";
import { fetchPerformanceScore } from "store/actions/CreativeAI_Revamp/HomeScreen/Editor/editor.actions";
import { AdAccountState } from "store/types/adaccount";
import { performanceScoreNullState } from "components/CreativeAI_Revamp/shared/PerformanceTooltip/performance.constant";
import { FBHomeScreenInitialStateType } from "store/types/CreativeAI_Revamp/HomeScreen/homeScreen.types";
import { checkAdType } from "components/CreativeAI/Facebook/utils/common.utils";
import { PerformanceScoreType } from "store/types/CreativeAI_Revamp/HomeScreen/Editor/editor.types";

interface OptionsComponentProps {
  titleText: string;
  handleChange: (value: string, type: HashType) => void;
  value?: string | number;
  options: RecommendationMetaDataItem;
  hash: HashType;
  showMoreData: (hash: HashType) => void;
  isLoading?: boolean;
  setDirty?: () => void;
  mixPanelCreativeStudioSeeMore: any;
}

export default function OptionsComponent({
  handleChange,
  value,
  titleText,
  options,
  hash,
  showMoreData,
  isLoading,
  setDirty,
  mixPanelCreativeStudioSeeMore,
}: OptionsComponentProps) {
  const [moreData, setMoreData] = useState(false);
  const optionToBeShown = options?.data?.slice(0, options?.noOfElementToShow);
  const dispatch = useDispatch();
  const { recommendationMetaData } = useSelector(
    (state: { createVariantReducer: CreateVariantsState }) =>
      state.createVariantReducer
  );
  const { adAccountsSelection } = useSelector(
    (state: { fbHomeScreenReducer: FBHomeScreenInitialStateType }) =>
      state.fbHomeScreenReducer
  );

  const { createSpec, selectedAd } = useSelector(
    (state: { createVariantReducer: CreateVariantsState | any }) =>
      state.createVariantReducer
  );
  const { BUSINESS_UNIT_BY_ID_DATA } = useSelector(
    (state: { selectCohortReducer: any; MainReducer: any }) => ({
      BUSINESS_UNIT_BY_ID_DATA: state.MainReducer.variantWithId,
    })
  );
  // redux state
  const { selectedBrand } = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );

  const onSave = (index: number, value: string, isSelected: boolean) => {
    const localRecommendationData = {
      ...recommendationMetaData,
      isEditing: true,
      isDeleting: false,
    };
    const editingItem = localRecommendationData[hash].data[index];
    let isEdited = undefined;
    if (!editingItem?.isEdited) {
      if (value.trim() !== editingItem?.text?.trim()) {
        isEdited = true;
      }
    }

    localRecommendationData[hash].data[index] = {
      ...localRecommendationData[hash].data[index],
      text: value,
      isLoading: true,
      isEdited,
    };
    dispatch(
      updateRecommendationMetadata({
        data: localRecommendationData,
      })
    );
    if (value?.length > 0) {
      dispatch(
        fetchPerformanceScore({
          body: {
            account_id: adAccountsSelection?.selectedAdAccountId as string,
            ad_type:
              checkAdType(createSpec) === "link_data"
                ? "SINGLE_IMAGE_AD"
                : "VIDEO_AD",
            ad_id: selectedAd?.id,
            assets: [
              {
                asset_type: hash,
                asset_text: value,
              },
            ],
            bu_name: BUSINESS_UNIT_BY_ID_DATA?.businessUnit,
            bu_detail: BUSINESS_UNIT_BY_ID_DATA?.businessUnitDetail,
            is_bundle: false,
          },
          callback: ({ error, performanceScore, cancel }) => {
            if (cancel) {
              localRecommendationData[hash].data[index] = {
                text: localRecommendationData[hash].data[index]?.text,
                ...performanceScoreNullState,
                isFailed: true,
              };
              dispatch(
                updateRecommendationMetadata({
                  data: localRecommendationData,
                })
              );
            }
            if (error) {
              localRecommendationData[hash].data[index] = {
                ...localRecommendationData[hash].data[index],
                isFailed: true,
              };
            } else {
              localRecommendationData[hash].data[index] = {
                ...localRecommendationData[hash].data[index],
                ...(performanceScore as PerformanceScoreType[])?.[0],
              };
              delete localRecommendationData[hash].data[index]?.isFailed;
            }
            delete localRecommendationData[hash].data[index]?.isLoading;
            dispatch(
              updateRecommendationMetadata({
                data: localRecommendationData,
              })
            );
          },
        })
      );
    } else {
      localRecommendationData[hash].data[index] = {
        ...localRecommendationData[hash].data[index],
        benefit_label: null,
        brand_centric: null,
        customer_centric: null,
        offer_label: null,
        performance_labels: "No_Text",
        performance_probabilities: 0,
        performance_score: 0,
        readability_score: 0,
      };
      delete localRecommendationData[hash].data[index]?.isLoading;
      dispatch(
        updateRecommendationMetadata({
          data: localRecommendationData,
        })
      );
    }

    if (isSelected) {
      handleChange(value, hash);
    } else {
      dispatch({
        type: UPDATE_RECOMMENDATION_SELECTION_STATE,
        payload: {
          isChanged: true,
        },
      });
    }
  };

  const [isDeleteDisabled, setIsDeleteDisabled] = useState<boolean>(
    !(optionToBeShown?.length > 1)
  );
  const onDelete = (index: number, isSelected: boolean) => {
    const localData = recommendationMetaData[hash].data.filter(
      (item: string) => item !== recommendationMetaData[hash].data[index]
    );
    if (localData.length <= 1) {
      setIsDeleteDisabled(true);
    }

    dispatch(
      updateRecommendationMetadata({
        data: {
          ...recommendationMetaData,
          [hash]: {
            ...recommendationMetaData[hash],
            data: localData,
            showMore:
              localData.length > recommendationMetaData[hash].noOfElementToShow,
          },
          isDeleting: true,
          isEditing: true,
        },
      })
    );

    if (isSelected) {
      if (localData[index]) {
        handleChange(localData[index], hash);
      } else {
        handleChange(localData[index - 1], hash);
      }
    } else {
      dispatch({
        type: UPDATE_RECOMMENDATION_SELECTION_STATE,
        payload: {
          isChanged: true,
        },
      });
    }
  };

  const displayNewTag = function (l: number, i: number) {
    let options = Array.from({ length: l }, (_, i) => i + 1)
      .filter((i) => i % 4 == 0)
      .reverse();
    if (i == options[0] - 4 && i != 0)
      return (
        <StyledNewIcon>
          <NewIcon />
        </StyledNewIcon>
      );
    else if (options.length == 1 && i == options[0])
      return (
        <StyledNewIcon>
          <NewIcon />
        </StyledNewIcon>
      );
    else return null;
  };

  return (
    <StyledOptionsContainer id={hash} key={hash}>
      {optionToBeShown && optionToBeShown.length ? (
        <StyledRecommendationTitle>Recommendations</StyledRecommendationTitle>
      ) : (
        <Fragment />
      )}
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={(e) => {
          handleChange((e.target as HTMLInputElement).value, hash);
          if (!!setDirty) setDirty();
        }}
        className="p-0 m-0"
      >
        {optionToBeShown && optionToBeShown.length ? (
          <StyledIntroText>{titleText}</StyledIntroText>
        ) : (
          <Fragment />
        )}
        {optionToBeShown && optionToBeShown.length ? (
          optionToBeShown.map((option, index) => (
            <>
              {displayNewTag(optionToBeShown.length, index)}
              <EditableTextRadio
                item={option}
                radioValue={option?.text}
                isSelected={option?.text === value}
                key={index}
                hash={hash}
                index={index}
                onSave={onSave}
                isLoading={!!isLoading}
                onDelete={onDelete}
                isDeleteDisabled={isDeleteDisabled}
              />
            </>
          ))
        ) : (
          <NoRecommendations />
        )}

        <StyledButtonDiv>
          {options.showMore && (
            <StyledShowMore
              onClick={() => {
                showMoreData(hash);
                mixPanelCreativeStudioSeeMore();
                setMoreData(true);
              }}
            >
              Show More
            </StyledShowMore>
            // <Button
            //   buttonVariant="underline"
            //   onClick={() => showMoreData(hash)}
            // >
            //   Show More
            // </Button>
          )}
        </StyledButtonDiv>
      </RadioGroup>
    </StyledOptionsContainer>
  );
}
