import React, { Fragment, useEffect, useState } from "react";
import TextArea from "../../../../../shared/Input/TextArea/TextArea";
import { ReactComponent as Save } from "../../../../../../assets/svg/save.svg";
import { ReactComponent as Edit } from "../../../../../../assets/svg/edit_.svg";
import { Box, IconButton, Radio, Tooltip } from "@mui/material";
import { CreateVariantsState } from "../../../../../../store/types/CreativeAI/Facebook/CreateVariant/createVariant.types";
import { useSelector } from "react-redux";
import { ReactComponent as Bin } from "../../../../../../assets/svg/bin.svg"; // might need in V5

import PerformanceTooltip from "components/CreativeAI_Revamp/shared/PerformanceTooltip/PerformanceTooltip";
import ErrorPerformanceScore from "../../../shared/PerformanceScore/ErrorPerformanceScore";
import PerformanceScoreInfo from "components/CreativeAI/Facebook/shared/PerformanceScore/PerformanceScoreInfo";
import LoadingPerformanceScore from "components/CreativeAI/Facebook/shared/PerformanceScore/LoadingPerformanceScore";
import { StyledTextSpace } from "components/CreativeAI/Facebook/shared/PerformanceScore/styled";
import { StyledMainContainer } from "./styled";
import { RecommendationDataType } from "store/types/CreativeAI_Revamp/HomeScreen/Editor/editor.types";
import { HashType } from "../Editor.types";
interface EditableTextRadioProps {
  onSave?: (index: number, value: string, isSelected: boolean) => void;
  onEdit?: () => void;
  defaultValue?: string;
  isSelected: boolean;
  backgroundColor?: string;
  borderColor?: string;
  item: RecommendationDataType;
  radioValue?: string | number;
  hash: string;
  index: number;
  isLoading?: boolean;
  onDelete?: (index: number, isSelected: boolean) => void;
  isDeleteDisabled: boolean;
}

export default function EditableTextRadio({
  onEdit,
  onSave,
  defaultValue,
  isSelected,
  item,
  radioValue,
  hash,
  index,
  isLoading,
  onDelete,
  isDeleteDisabled,
}: EditableTextRadioProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [inputState, setInputState] = useState("");

  const { recommendationMetaData } = useSelector(
    (state: { createVariantReducer: CreateVariantsState }) =>
      state.createVariantReducer
  );

  const onChange = (event: any) => {
    setInputState(event.target.value);
  };

  useEffect(() => {
    if (
      !recommendationMetaData?.isEditing ||
      recommendationMetaData?.isDeleting ||
      inputState.length === 0
    ) {
      setInputState(recommendationMetaData[hash].data[index]?.text);
    }
  }, [recommendationMetaData]);

  // performance tooltip related code
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handlePerformancePopoverOpen = (event: any) => {
    setAnchorEl(event?.currentTarget);
  };

  const handlePerformancePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPerformanceTooltip = Boolean(anchorEl);

  // tooltip code end here

  const showPerformanceScore = () => {
    if (item?.isLoading) {
      return <LoadingPerformanceScore />;
    } else if (item?.isFailed) {
      return (
        <ErrorPerformanceScore
          index={index}
          hash={hash as HashType}
          value={recommendationMetaData[hash].data[index]?.text}
        />
      );
    } else if (item?.performance_labels || item?.data?.tags ) {
      return (
        <PerformanceScoreInfo
          handlePerformancePopoverOpen={handlePerformancePopoverOpen}
          handlePerformancePopoverClose={handlePerformancePopoverClose}
          infoItem={item}
        />
      );
    } else {
      return <Box />;
    }
  };

  return (
    <StyledMainContainer id={hash + index}>
      <Radio
        size="small"
        className="mr-1"
        value={radioValue}
        checked={isSelected}
        disabled={isLoading}
      />
      <StyledTextSpace selected={isSelected}>
        <Box display="flex" justifyContent="space-between">
          {showPerformanceScore()}
          {item?.insight_generation && (
            <Tooltip
              title="This is a new type of Primary Text recommendations which are generated using Creative Insights. "
              arrow
              placement="top"
              componentsProps={{
                tooltip: {
                  sx: {
                    background: "#FFF",
                    color: "#333333",
                    padding: "0.5",
                    filter: "drop-shadow(0px 1px 12px rgba(0, 0, 0, 0.10))",
                    "& .MuiTooltip-arrow": {
                      color: "#FFF",
                    },
                  },
                },
              }}
            >
              <Box
                sx={{
                  borderRadius: "0rem 0.4375rem",
                  border: " 1px solid  #DCBBF9)",
                  background: "#F6EEFE",
                  fontSize: "0.75em",
                  fontStyle: "normal",
                  fontWeight: 400,
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  fontFamily: "Inter",
                  padding: "0.5em 0.55em",
                  color: "#A754F0",
                  alignSelf: "flex-end",
                }}
              >
                Insights Driven
              </Box>
            </Tooltip>
          )}
        </Box>
        <TextArea
          defaultValue={defaultValue}
          value={inputState}
          onChange={onChange}
          readOnly={!isEditing}
          // isBorder={isEditing || isSelected}
          disableUnderline
          backgroundColor="#FFFFFF"
          // borderColor="#FFFFFF"
          // backgroundColor={
          //   isEditing ? "white" : isSelected ? "#FBFCFF" : undefined
          // }
          // borderColor={isEditing ? "#E5E5E5" : isSelected ? "#0869FB" : undefined}
          sx={{ fontSize: "14px", fontWeight: 400, fontFamily: "Inter" }}
          height="unset"
          minRows={1}
          maxRows={2}
          disabled={isLoading}
        />
        {item?.isEdited && (
          <Box
            display="flex"
            justifyContent="flex-end"
            sx={{
              color: " #82838F",
              fontFamily: "Inter",
              fontSize: "0.75em",
              fontStyle: "italic",
              fontWeight: 400,
              lineHeight: "20px",
              margin: "0 0.75em 0.5em 0",
            }}
          >
            (Edited)
          </Box>
        )}
      </StyledTextSpace>
      <IconButton
        color="primary"
        aria-label="save"
        className="ml-3 p-0"
        disabled={
          isLoading || (inputState?.length === 0 && hash === "primary_text")
        }
        onClick={() => {
          if (isEditing) {
            setIsEditing(false);
            if (inputState !== recommendationMetaData[hash].data[index]?.text) {
              onSave &&
                onSave(
                  index,
                  inputState,
                  isSelected &&
                    inputState !== recommendationMetaData[hash].data[index]
                );
            }
          } else {
            setIsEditing(true);
            onEdit && onEdit();
          }
        }}
      >
        {isEditing ? <Save /> : <Edit />}
      </IconButton>
      <IconButton
        color="primary"
        aria-label="save"
        className="ml-2 p-0"
        disabled={isLoading || isDeleteDisabled}
        onClick={() => {
          onDelete && onDelete(index, isSelected);
        }}
      >
        <Bin />
      </IconButton>
      <PerformanceTooltip
        open={openPerformanceTooltip}
        anchorEl={anchorEl}
        infoItem={item}
        place="editor"
      />
    </StyledMainContainer>
  );
}
