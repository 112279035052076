import React, { useEffect, useState } from "react";
import BrandSettingTable from "./BrandSettingTable";
import {
  StyledContainer,
  StyledTitle,
  StyledHeaderContainer,
  StyledHeadTitle,
  StyledLinkItem,
  StyledAnimaterToggle,
} from "./styled";
import { useDispatch, useSelector } from "react-redux";
import BusinessUnitModal from "./BusinessUnitModal";
import { initialState } from "../../../store/types/auth";
import TooltipWithInfo from "../Facebook/shared/TooltipWithInfo/TooltipWithInfo";
import { ReactComponent as AddIcon } from "../../../assets/svg/buAddIcon.svg";
import { Button, StyledText } from "../../shared";
import CustomizedDialogs from "../../shared/Modal/CustomizedDialogs";
import { Box, Stack } from "@mui/material";
import Loader from "../../common/Loader";
import { AdAccountState } from "../../../store/types/adaccount";
import MediaStudioAdmin from "./MediaStudioAdmin/MediaStudioAdmin";
import CustomizedSwitch from "components/shared/Switch/CustomizedSwitch";
import { toggleCreativeAnimater } from "store/actions/CreativeAI/Facebook/Main/Main";

const BrandSetting: React.FC = () => {
  const dispatch = useDispatch();
  const [creativeAnimaterToggle, setCreativeAnimaterToggle] = useState<
    boolean | undefined
  >();

  const { singleBusinessUnit, businessUnits, deleteBusinessUnitState } =
    useSelector((state: any) => state.businessUnitsReducer);

  const [userConfirmation, setUserConfirmation] = useState<{
    uiState: boolean;
    userInput: boolean;
  }>({
    uiState: false,
    userInput: false,
  });

  const auth = useSelector((state: { auth: initialState }) => state.auth);
  const adaccount = useSelector(
    (state: { adaccount: AdAccountState }) => state.adaccount
  );
  const { selectedBrand } = adaccount;

  useEffect(() => {
    dispatch({ type: "GET_ALL_BUSINESS_UNITS", payload: params });
    dispatch({ type: "GENERATE_LEVERS" });
  }, []);

  const [showUnitForm, setShowUniteForm] = useState(false);

  const [editState, setEditState] = useState(false);

  const handelToggleModal = () => {
    setShowUniteForm(!showUnitForm);
    setEditState(false);
  };

  const initialParams = {
    searchField: "",
    search: "",
    order: "ASC",
    orderBy: "businessUnit",
    limit: "50",
    page: "",
    brandId: selectedBrand?.id,
  };

  const paramsReducer = (state: any, action: any) => {
    switch (action.type) {
      case action.type:
        return { ...state, [action.type]: action.payload };
      default:
        return state;
    }
  };

  const [params, paramsDispatch] = React.useReducer(
    paramsReducer,
    initialParams
  );
  useEffect(() => {
    setCreativeAnimaterToggle(selectedBrand?.isCreativeAnimator);
  }, [selectedBrand?.isCreativeAnimator]);

  const handleBusinessEdit = (id: string = "") => {
    setShowUniteForm(true);
    setEditState(true);
    dispatch({ type: "GET_BUSINESS_UNIT_BY_ID", payload: { id } });
  };

  const handleBusinessSave = (formData: any) => {
    dispatch({
      type: "ADD_BUSINESS_UNIT",
      payload: { user: auth.user, formData, brandId: selectedBrand?.id },
    });
  };
  const handleBusinessUpdate = (id: any, creativeAiId: any, formData: any) => {
    dispatch({
      type: "UPDATE_BUSINESS_UNIT",
      payload: {
        id,
        creativeAiId,
        user: auth.user,
        formData,
        brandId: selectedBrand?.id,
      },
    });
  };

  const handleBusinessUnitDelete = (id: string = "") => {
    // if (window.confirm("Are you sure you want to delete")) {

    dispatch({
      type: "DELETE_BUSINESS_UNIT_BY_ID",
      payload: { id, user: auth.user, brandId: selectedBrand?.id },
    });

    // }
  };

  return (
    <>
      {userConfirmation && (
        <CustomizedDialogs
          handleClose={() =>
            setUserConfirmation({ ...userConfirmation, uiState: false })
          }
          show={userConfirmation.uiState}
          type="ConfirmDialog"
        >
          <p>Are you sure you want to delete?</p>
          <Stack direction={"row"} justifyContent="space-between">
            <Button
              variant="contained"
              onClick={() => {
                setUserConfirmation({
                  ...userConfirmation,
                  uiState: false,
                  userInput: false,
                });
              }}
            >
              NO
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                setUserConfirmation({
                  ...userConfirmation,
                  uiState: false,
                  userInput: false,
                });
              }}
            >
              YES
            </Button>
          </Stack>
        </CustomizedDialogs>
      )}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <StyledTitle>{`Creative AI Admin Control`}</StyledTitle>
        {/* TODO: Might need this for future releases, hence keeping it for now */}
        {/* <StyledAnimaterToggle>
          <StyledText fontSize="20px" lineHeight="24px">
            Creative Animator
          </StyledText>
          <CustomizedSwitch
            checked={creativeAnimaterToggle}
            onClick={(e: any) => {
              dispatch(
                toggleCreativeAnimater({
                  brandId: selectedBrand?.id,
                  isCreativeAnimator: !creativeAnimaterToggle,
                })
              );
              setCreativeAnimaterToggle((prev) => !prev);
            }}
          />
        </StyledAnimaterToggle> */}
      </Box>

      <StyledContainer className="rounded">
        <Box height="50%">
          <StyledHeaderContainer>
            <StyledHeadTitle>
              Business Units
              <TooltipWithInfo info="Business unit refers to an independent vertical of the brand. For example, if the brand is a bank, then, personal loan or credit card is a business unit." />
            </StyledHeadTitle>
            <StyledLinkItem onClick={() => setShowUniteForm(true)}>
              <AddIcon style={{ marginRight: "10px" }} /> Add Business Unit
            </StyledLinkItem>
          </StyledHeaderContainer>
          {businessUnits?.loading ? (
            <Loader />
          ) : (
            <div style={{ width: "100%", overflow: "scroll", height: "85%" }}>
              {businessUnits?.loading === false && (
                <BrandSettingTable
                  handleBusinessEdit={handleBusinessEdit}
                  handleBusinessUnitDelete={handleBusinessUnitDelete}
                  businessUnits={businessUnits?.data}
                />
              )}
            </div>
          )}
        </Box>
        <MediaStudioAdmin />
      </StyledContainer>

      {showUnitForm && (
        <BusinessUnitModal
          editState={editState}
          handleBusinessSave={handleBusinessSave}
          handleBusinessUpdate={handleBusinessUpdate}
          onHide={() => handelToggleModal()}
          showUnitForm={showUnitForm}
          setShowUniteForm={setShowUniteForm}
          singleBusinessUnit={singleBusinessUnit}
        />
      )}
    </>
  );
};

export default BrandSetting;
